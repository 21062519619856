
.bloags h1 {
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  font-size: 64px;
  
}

.blogs .blog-card {
  background: rgb(245, 246, 250);
  color: rgb(11, 14, 20);
  overflow: hidden;
  gap: 16px;
  transition: 100ms;
  border-radius: 4px;
  border: 1px solid rgba(194, 201, 214, 0.4);
  box-shadow: none;
  background: rgb(245, 246, 250);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 300px;
  max-width: 350px;
  cursor: pointer;
}

.blogs .blog-card:hover {
  background: rgb(255, 255, 255);
}

.blog-card .content {
  padding: 10px;
  padding-top: 0;
  padding-bottom: 16px;
  margin: 0px 0px 0.35em;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: Inter, "sans-serif";
  line-height: 1.6;
}

.blog-card .content .date {
  margin: 0px;
  font-size: 0.72rem;
  font-weight: 400;
  font-family: Inter, "sans-serif";
  line-height: 1.66;
}
