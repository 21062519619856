.press-release-container a{
    padding: 20px;
    border-radius: 10px;
    background: #fbfbfb;
    min-height: 150px;
}
.press-release-container a:hover{
    background: #ffffff;
}
.press-release-container img{
    width: 40%;
    border-radius: 4px;
}

.press-release-container .link {
   font-size: 16px;
  }


.press-release-container a:hover  .link {
    text-decoration: underline;
    color: blue;
  }