@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
.blog-content {
  font-family: "Nunito Sans", sans-serif;
}

.blog-head h2 {
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
 

.blog-content .sub-head{
    font-size: 18px;
    line-height: 30px;
    color: rgb(74, 73, 72);
    font-weight: 600;
}

.blog-content .para {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    text-align: justify;
}

.blog-content .qoutes{
    font-style: italic;
    color: rgb(83, 85, 87);
}

 
.blog-content .contact:hover {
  background: #f9f9f9;
}

.blog-content .contact {
  border-radius: 8px;
  border: 1px solid #006fff;
  color: #006fff;
  font-style: normal;
  font-weight: 600;
  padding: 10px 24px;
  text-align: center;
}