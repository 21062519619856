.aboutus-bg-blue {
  border-radius: 8px;
  background: rgba(62, 139, 255, 0.5);
}

.aboutus-para {
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

@media (min-width: 300px) {
  .our-vision-3 {
    margin-top: 660px ;
  }
}

@media (min-width: 380px) {
  .our-vision-3 {
    margin-top: 600px ;
  }
}

@media (min-width: 390px) {
  .our-vision-3 {
    margin-top: 620px ;
  }
}

@media (min-width: 450px) {
  .our-vision-3 {
    margin-top: 500px ;
  }
}

@media (min-width: 500px) {
  .our-vision-3 {
    margin-top: 450px ;
  }
}

@media (min-width: 550px) {
  .our-vision-3 {
    margin-top: 350px ;
  }
}

@media (min-width: 600px) {
  .our-vision-3 {
    margin-top: 300px ;
  }
}

@media (min-width: 740px) {
  .our-vision-3 {
    margin-top: 280px ;
  }
}





  .custom-loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#ffffff 94%,#0000) top/5px 5px no-repeat,
      conic-gradient(#0000 20%,#f8f8f8);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
    animation:s3 1s infinite linear;
  }
  
  @keyframes s3{ 
    100%{transform: rotate(1turn)}
  }

  .serverError{
    color: #ef1d1d;
    font-size: 12px;
    font-weight: 500;
  }

  
.selected-flag, .flag-dropdown  {
  background: none !important;
  outline: none !important;
  width: 0px !important;
  border: 0px !important;
  padding: 0 !important;
}

form .open{
  color: #000 !important;
  font-size: 12px;
  font-weight: 400;
}

.form-control {
  padding-left: 48px !important; 
}

.react-tel-input .form-control{
  border:none !important;
  width: 90px !important

}