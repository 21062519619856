/* @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"); */

.top_section {
  max-width: 1240px !important;
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.menu {
  color: black;
}

.active-nav-link {
  color: #006fff !important;
  font-weight: 500 !important;
}

.active-link {
  color: #d72a1d !important;
  font-weight: bold !important;
}

.section_wrap {
  width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

.top-nav ul li {
  margin-right: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.top-nav ul li:hover {
  color: #224ed4;
}

.top-navbar ul li {
  margin-right: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.top-navbar ul li:hover {
  color: #5c5c5c;
}

.demo-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #006fff !important;
  border: 1px solid #006fff !important;
  border-radius: 4px !important;
  padding: 12px 24px;
}

.demo-btn:hover {
  color: #224ed4 !important;
  border: 1px solid #224ed4 !important;
}

.sign-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  background: #006fff !important;
  color: white !important;
  margin-left: 32px !important;
  border-radius: 4px !important;
  padding: 13px 32px;
}

.sign-btn:hover {
  background: #224ed4 !important;
}

.topNav {
  width: 100%;
  height: 72px;
  background-color: #e4f3f4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.head1 {
  flex-direction: column;
}

h1 {
  color: var(--black, #000);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -0.50px;
}

h2 {
  color: var(--black, #000);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.96px;
  text-transform: capitalize;
}

h3 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -0.64px;
}

h4 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 33px; 
}

p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.08px;
}

.careerCbg {
  border-radius: 6.088px;
  background: linear-gradient(90deg, #0c4998 0%, #006fff 100%);
}

.contentD {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 70%;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.open {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 18px !important;
  background: #006fff;
  border: 0;
  border-radius: 4px;
}

.open:hover {
  background: #224ed4 !important;
}

.bg-custom-gradient {
  background: linear-gradient(
    180deg,
    rgba(161, 196, 253, 0.2) 0%,
    rgba(251, 254, 255, 0.2) 100%
  );
}

.careerImg {
  width: 500px;
  height: 364.25px;
  border-radius: 8px;
}

.careerD {
  margin-bottom: 100px;
  padding: 80px 0px 80px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(90deg, #b7e6eb 0%, #a3e3e9 50%, #73c1c6 100%);
  text-align: center;
}

.careerC {
  display: flex;
  flex-direction: row;
}

.girlText {
  cursor: pointer;
  color: #1447d4;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.girlText:hover {
  color: #7e99e5;
}

.gap {
  color: #1447d4;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
}

.search {
  display: flex;
  padding: 8px 8px 8px 40px;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  border: 1px solid #2f2f2f;
}

.searchButton {
  padding: 10px 24px;
  align-items: center;
  border-radius: 32px;
  background: #006fff;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.16px;
}

.searchButton:hover {
  background: #224ed4 !important;
}

.dropdownButton {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #c1c1c1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
}

.frame-280 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* .frame-169 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 824px;
  position: relative;
} */

.frame-171:active {
  background: #ffffff;
  border-radius: 116px;
  border-style: solid;
  border-color: #000000;
  border-width: 1px;
  padding: 16px 36px 16px 36px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.cata:hover {
  color: #000000;
  text-align: left;
  font: 700 16px "Manrope", sans-serif;
  position: relative;
}

.frame-171 {
  border-radius: 116px;
  border-style: solid;
  border-color: #c1c1c1;
  border-width: 1px;
  padding: 16px 36px 16px 36px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.cata {
  color: #4e4e4e;
  text-align: left;
  font: 600 16px "Manrope", sans-serif;
  position: relative;
}

.line-1 {
  border-style: solid;
  border-color: #c1c1c1;
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

.job-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  position: relative;
}

.jobs:hover {
  background-color: #eeeeee;
}

.jobs {
  background: #ffffff;
  border-radius: 42px;
  border-style: solid;
  border-color: #2f2f2f;
  border-width: 1px;
  padding: 32px 48px 32px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 824px;
  position: relative;
}

.jobTitle {
  color: #000000;
  text-align: left;
  font: 700 24px "Manrope", sans-serif;
  position: relative;
}

.frame-177 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.material-symbols-location-on-outline-rounded {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.location {
  color: #000000;
  text-align: left;
  font: 600 18px "Manrope", sans-serif;
  position: relative;
}

.frame-180 {
  background: #ffffff;
  border-radius: 42px;
  border-style: solid;
  border-color: #c1c1c1;
  border-width: 1px;
  padding: 32px 48px 32px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 824px;
  position: relative;
}

.material-symbols-location-on-outline-rounded2 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.frame-181 {
  background: #ffffff;
  border-radius: 42px;
  border-style: solid;
  border-color: #c1c1c1;
  border-width: 1px;
  padding: 32px 48px 32px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 824px;
  position: relative;
}

.material-symbols-location-on-outline-rounded3 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.frame-182 {
  background: #ffffff;
  border-radius: 42px;
  border-style: solid;
  border-color: #c1c1c1;
  border-width: 1px;
  padding: 32px 48px 32px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 824px;
  position: relative;
}

.material-symbols-location-on-outline-rounded4 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.form {
  display: flex;
  padding: 30px 28px;
  flex-direction: column;
  gap: 18px;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  background: #fff;
}

.label {
  color: #454343;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.form-field , .error-field {
  padding: 4px 14px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1.5px solid #dcdcdc;
  outline: none;
  min-height: 40px;
  font-size: 13px;
}

.error-field, .error-field {
  border-color: #ef4444 !important;
}

.form-field:focus,.form-field:active, .error-field:active, .error-field:focus {
  border-color: #0359ca;
}

.form-field::placeholder, .error-field::placeholder {
  color: #7d7878;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}

.sub-btn {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: #006fff;
  color: #fbfbfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}

.sub-btn:hover {
  background: #224ed4 !important;
}

.logForm {
  display: flex;
  padding: 56px 32px 48px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
}

.sign-field {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  border: 0.5px solid #006fff;
  background: #fff;
}

.bgAbout {
  border-radius: 8px;
  background: rgba(62, 139, 255, 0.5);
  height: 318px;
}

.aboutImg {
  width: 604px;
  height: 412px;
}

.aboutCard {
  padding: 24px;
  gap: 32px;
  border-radius: 32px;
  border: 1px solid #fff;
  color: #fff;
  background: #006fff;
  min-height: 280px;
}

.cardH {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.12px;
  text-align: left;
}

.cardP {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.08px;
}

.aboutNo {
  color: #006fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -1.28px;
}

.aboutRt {
  color: #006fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.4px;
  text-align: center;
}

.aboutCount {
  border-radius: 24px;
  border: 1px solid #87bbff;
  background: #fff;
  padding: 88px 120px 64px 120px;
}

.aboutLine {
  height: 64px;
  width: 3px;
  border-radius: 4px;
  background: #006fff;
}

.presenceCard {
  display: flex;
  flex-direction: column;
  padding: 48px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid #9e9e9e;
  background: #fff;
  min-height: 250px;
}

.demo {
  padding: 18px 24px;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  background: #006fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}

.demo:hover {
  background: #224ed4 !important;
}

.cardCus {
  display: flex;
  padding: 24px 24px 24px 56px;
  justify-content: center;
  align-items: center;
  justify-content: space-between !important;
  gap: 40px;
  border-radius: 22px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 16px 12px 27px 0px rgba(83, 83, 83, 0.06);
}

.card-inner-card {
  border-radius: 16px;
  background: #f9f9ff;
  padding: 20px;
  font-size: 13px;
  max-height: 240px;
  overflow-y: auto;
}

.cardCus:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.pro:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  transform: scale(1.01);
}

.presenceCard:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  transform: scale(1.01);
}

.fea:hover {
  transform: scale(1.02);
  transition: 0.2s;
}

.ucxCard {
  border-radius: 136px;
  border: 1px solid #89bcff;
  box-shadow: 16px 16px 20px 0px rgba(228, 228, 228, 0.25);
  cursor: pointer;
}

.ucxCard:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.ucxH {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.footerLi {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
}

.productH {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.productP {
  color: var(--para, #797979);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.08px;
}

.nav-links:hover {
  text-decoration: underline;
  cursor: pointer;
}

.images {
  animation: sliderscroll 40s linear infinite;
}

.faq {
  color: var(--black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.toggle-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.9s ease-in-out;
}

.toggle-container.visible {
  max-height: 200px;
}

.search-bar {
  border-radius: 8px;
  border: 0.5px solid #9e9e9e;
  background: #fff;
  padding: 6px 16px;
  outline: none;
}

.search-bar::placeholder {
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 500;
}

.search-bar:hover,
.search-bar:focus {
  border: 0.5px solid #3d38af;
}

.exportBtn {
  padding: 10px 18px;
  border-radius: 6px;
  background: #3d38af;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.exportBtn:hover {
  background: #16108d;
}

.shadow {
  transition: 1s ease;
}

.shadow:hover {
  border-radius: 50%;
  transition: 1s ease;
}

.swiper-button-prev,
.swiper-button-next {
  width: 5px !important;
  height: 5px !important;
}

div[data-tag="allowRowEvents"] {
  white-space: normal !important;
}

.meet {
  justify-content: center !important;
}

.bgSect1 {
  background: linear-gradient(180deg, #f2f5fb 0%, rgba(255, 255, 255, 0) 100%);
}

.login-card {
  border-radius: 16px;
  border: 1px solid #cdcdcd;
  padding: 40px 60px 64px 60px;
  min-width: 500px;
}

.login-head {
  color: #006fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-card label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login-card input {
  border-radius: 8px;
  background: #f1f1f1;
  padding: 10px;
  outline: none;
  width: 100%;
  font-size: 14px;
  border: 1.2px solid #b9b9bc;
}

.login-card input::placeholder {
  color: #b6b6b6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.login-card input:hover {
  border: 1px solid #006fff;
}

.login-card input:focus {
  border: 1.2px solid #006fff;
}

.login-card button {
  padding: 12px 5px;
  border-radius: 8px;
  background: #006fff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.error-div {
  color: #dd1111;
  font-size: 12px;
  font-weight: 500;
}

.carousel.carousel-slider {
  background-color: transparent !important;
}

.carousel .slide {
  background: transparent !important;
  width: 50% !important;
}

/* .carousel .slider-wrapper {
  border-radius: 22px !important;
} */

.carousel.carousel-slider .control-arrow {
  height: 40px !important;
  width: 40px !important;
  background-color: #dee2ed !important;
  border-radius: 9999px !important;
  border: 1px solid #adacac !important;
  color: black !important;
  top: 50% !important;
  align-items: center !important;
  justify-content: center;
}

.carousel .control-prev.control-arrow {
  padding: 5px !important;
  background-image: url("../image/Prev.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.carousel .control-next.control-arrow {
  background-image: url("../image/Next.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  display: none !important;
}

.smartdiv {
  border-radius: 8px;
  border: 2px solid #cde3ff;
}

.box1 {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: rgba(236, 255, 232, 0.6);
}

.box2 {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: rgba(237, 232, 255, 0.6);
}

.box3 {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: rgba(232, 234, 255, 0.6);
}

.boxH {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.24px;
}

.boxP {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.2px;
}

.boxList {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.18px;
  text-transform: capitalize;
}

.enhance {
  color: #000;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
}

.enhanceP {
  color: #434343;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.2px;
}

.smartCards {
  padding: 40px 24px 24px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border-bottom: 1px solid #c7c7c7;
}

.smartcardH {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  text-transform: capitalize;
}

.smartcardP {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.16px;
}

.conclude {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.24px;
}

.avaya {
  padding: 28px 56px;
  border-radius: 80px;
  border: 1px solid #e4e4e4;
  background: #f4f4f4;
}

.box-1 {
  padding: 40px 32px;
  align-items: flex-start;
  border-radius: 24px;
  background: #f4f1ff;
}

.box-2 {
  padding: 40px 32px;
  align-items: flex-start;
  border-radius: 24px;
  background: #f1f2ff;
}

.box-3 {
  padding: 40px 32px;
  align-items: flex-start;
  border-radius: 24px;
  background: #f4fff1;
}

.box-4 {
  padding: 40px 32px;
  align-items: flex-start;
  border-radius: 24px;
  background: #f8f1ff;
}

.boxList1 {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.18px;
  text-transform: capitalize;
}

.CXNo {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -1.28px;
}

.CXRt {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.4px;
  text-align: center;
}

.CxLine {
  width: 3px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
}

.CxCount {
  padding: 60px 120px 48px 120px;
  align-items: center;
  background: #f6695e;
}

.cxCard {
  padding: 32px 24px;
  gap: 32px;
  background: linear-gradient(180deg, #fff5f5 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 16px 12px 36px 0px rgba(0, 0, 0, 0.05);
  min-height: 280px;
}

.offerCards {
  display: flex;
  padding: 40px 80px;
  justify-content: space-between;
  align-items: center;
  gap: 18px !important;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}

.offerH {
  color: #242424;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.64px;
  text-transform: capitalize;
}

.offerP {
  color: var(--sub, #5a5a5a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  /* 166.667% */
  letter-spacing: -0.48px;
}

.cxBtn {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: #d72a1d;
  color: #fbfbfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}

.products-grid .cards {
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  cursor: pointer;
  min-height: 300px;
}
.products-grid .cards .head {
  font-size: 23px;
  font-weight: 700;
}

.products-grid .cards:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  transform: scale(1.01);
}
.products-inner-page .cards-head {
  font-size: 20px;
  font-weight: 700;
}

.products-inner-page .cards-para {
  font-size: 16px;
  font-weight: 500;
}

.products-grid .para {
  color: #7a7a7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/* product inner pages */

/* .products-inner-page {
  font-family: "Lato", sans-serif;
} */

.product-header .head {
  font-size: 48px;
  font-weight: 600;
  width: fit-content;
  color: #fff;
}

.product-header .sub-head {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-top: 6px;
}

.products-inner-page .lists {
  color: #7a7a7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 200% */
}

.products-inner-page .feature-container,
.feature-container-1 {
  border-radius: 24px;
  background: #ebedff;
  padding: 28px;
}

.products-inner-page .feature-container .card {
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  min-height: 80px;
}

.products-inner-page .feature-container .card .head {
  font-size: 16px;
  font-weight: 800;
}

.products-inner-page .feature-container .card .para {
  font-size: 13px;
  font-weight: 400;
}

.products-inner-page .journey-container,
.journey-container-1 {
  border-radius: 30px;
  background: #f1f1f3;
  padding: 30px;
}

.products-inner-page .journey-container .ai-lists {
  color: #7a7a7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.18px;
}

.products-inner-page .journey-container .head,
.products-inner-page .heading {
  font-size: 37px;
  font-weight: 600;
}
.products-inner-page .journey-container .ai-head,
.products-inner-page .normal-head {
  font-size: 30px;
  font-weight: 600;
}

.products-inner-page .blue-head {
  color: #006fff;
  font-weight: 600;
  font-size: 24px;
}

.products-inner-page .sub-para {
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
}

.products-inner-page .sub-para-ai {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  border-radius: 4px;
  border-bottom: 1px solid #c7c7c7;
  padding: 30px 20px;
}

.products-inner-page .button-group {
  font-size: 16px;
}

.products-inner-page .button-group .schedule {
  border-radius: 8px;
  background: #006fff;
  padding: 10px 24px;
  font-style: normal;
  font-weight: 600;
  color: #ffff;
  text-align: center;
}

.products-inner-page .button-group .schedule:hover {
  background: #0359ca;
}
.products-inner-page .button-group .contact:hover {
  background: #f9f9f9;
}

.products-inner-page .button-group .contact {
  border-radius: 8px;
  border: 1px solid #006fff;
  color: #006fff;
  font-style: normal;
  font-weight: 600;
  padding: 10px 24px;
  text-align: center;
}

.containerd {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}
.itemd {
  background-color: lightblue;
  padding: 20px;
  text-align: center;
}
/* Example of varying heights */
.itemd:nth-child(odd) {
  height: 150px;
}
.itemd:nth-child(even) {
  height: 100px;
}

.products-inner-page .blue-cards {
  border-radius: 38px;
  background: #ebedff;
  padding: 24px;
  min-height: 200px;
}

.products-inner-page .blue-cards .blue-head {
  color: #006fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 150% */
}

.products-inner-page .blue-cards .para {
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.products-inner-page .blue-head-small {
  color: #006fff;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.products-inner-page .para-small {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.products-inner-page .starting-para,
.starting-para > p,
.products-inner-page p,
.feature-page p {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
}

.landing-page-container > p,
.landing-page-container p,
.career-page p,
.career-page > p,
.about-us-page p,
.about-us-page > p {
  text-align: justify;
}

.journey-container .feature-chips {
  border-radius: 148px;
  border: 1px solid #c7c7c7;
  background: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 20px;
}

.journey-container .feature-chips:hover {
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.products-inner-page .cobrowse-card {
  border-radius: 24px;
  background: #fbfbfb;
  padding: 24px;
  font-size: 18px;
  font-weight: 600;
}

.products-inner-page .use-case .blue-box {
  border-radius: 8px;
  background: #006fff;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 5px 24px;
}

.products-inner-page .use-case .blue-head {
  color: #006fff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.products-inner-page .use-case .sub-para {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 170% !important; /* 23.8px */
}

.products-inner-page .ticketing-cards {
  border-radius: 10px;
  background: #f0f1fe;
  font-size: 18px;
  font-weight: 500;
  padding: 24px;
}

.products-inner-page .no-bg-card {
  border-radius: 16px;
  border: 1px solid #000;
  padding: 24px;
  color: #434343;
  font-size: 19px;
  font-weight: 600;
}

.img-zoom:hover {
  z-index: 20;
  transition: transform 0.5s ease;
}

.zoom-image {
  transition: transform 0.5s ease; /* Smooth transition */
}

.zoom-image:hover {
  transform: scale(1.15); /* Zoom in the image */
  z-index: 20;
}

.custom-margin{
   top: -15px
}

 
.hero-animation .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
 }

 .hero-animation .layer1 {
  z-index: 1;
  animation: moveLayer1 5s infinite alternate ease-in-out;;
}

.hero-animation .layer2 {
  z-index: 2;
  animation: moveLayer2 5s infinite alternate ease-in-out;;
}

.hero-animation .layer3 {
  z-index: 3;
  animation: moveLayer3 5s infinite alternate ease-in-out;;
}

.hero-animation .layer4 {
  z-index: 4;
  animation: moveLayer4 5s infinite alternate ease-in-out;;
}

.hero-animation .layer5 {
  z-index: 5;
  animation: moveLayer5 5s infinite alternate ease-in-out;;
}

.hero-animation .layer6 {
  z-index: 6;
  animation: moveLayer6 5s infinite alternate ease-in-out;;
}

.hero-animation .layer7 {
  z-index: 7;
  animation: moveLayer7 5s infinite alternate ease-in-out;;
}

.hero-animation .layer8 {
  z-index: 8;
  animation: moveLayer8 5s infinite ease-in-out;;
}

.hero-animation .layer9 {
  z-index: 9;
  animation: moveLayer9 5s infinite alternate ease-in-out;;
}

/* Keyframes for animations */
@keyframes moveLayer1 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(3px, 5px);
  }
  50% {
      transform: translate(-4px, 7px);
  }
  75% {
      transform: translate(5px, -3px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer2 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(-2px, -4px);
  }
  50% {
      transform: translate(3px, -5px);
  }
  75% {
      transform: translate(-4px, 3px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer3 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(4px, -2px);
  }
  50% {
      transform: translate(-3px, 4px);
  }
  75% {
      transform: translate(2px, 3px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer4 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(-1px, 3px);
  }
  50% {
      transform: translate(5px, -2px);
  }
  75% {
      transform: translate(-6px, 4px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer5 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(2px, -6px);
  }
  50% {
      transform: translate(-3px, 1px);
  }
  75% {
      transform: translate(6px, -4px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer6 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(-4px, 2px);
  }
  50% {
      transform: translate(3px, -4px);
  }
  75% {
      transform: translate(-2px, 6px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer7 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(1px, 2px);
  }
  50% {
      transform: translate(-4px, -1px);
  }
  75% {
      transform: translate(6px, 1px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer8 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(-1px, -6px);
  }
  50% {
      transform: translate(4px, 4px);
  }
  75% {
      transform: translate(-3px, -4px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveLayer9 {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(5px, 5px);
  }
  50% {
      transform: translate(-1px, -2px);
  }
  75% {
      transform: translate(3px, 1px);
  }
  100% {
      transform: translate(0, 0);
  }
}

.custom-loader-contact {
  width:25px;
  height:25px;
  border-radius:50%;
  background:conic-gradient(#0000 10%,#F4F4F4);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  animation: customLoaderContact 1s infinite linear;
}
@keyframes customLoaderContact {to{transform: rotate(1turn)}}

.custom-toast{
  z-index: 9999999999999 !important;
  top: 70px !important;
  right: 0px !important;
 }

 .sign-up-lists li{
  color: #576b85;
  font-size: 14px;
 }

 .elevate-text {
  font-size: 22px; /* Increase the font size */
  font-weight: 600; /* Bold for emphasis */
  color: #79797b; /* A strong black color */
  text-align: left; /* Centered text */
   
 }


 @keyframes slideUpOvershoot {
  0% {
    transform: translateY(100%); /* Start from below the view */
    opacity: 0;
  }
  80% {
    transform: translateY(-10px); /* Move slightly above the final position */
    opacity: 1;
  }
  100% {
    transform: translateY(0); /* Settle at the original position */
    opacity: 1;
  }
}

.success-card {
  animation: slideUpOvershoot 0.6s ease-out;
  opacity: 1;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #e2f7fa;
}

.success-card h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #333;
}

.success-card p { 
  font-size: 16px;
  color: #555;
  line-height: 20px;

}