@media (max-width: 1190px) {
  .blog-head h2 { 
    font-size: 30px;
    font-weight: 600; 
  }
}


@media (max-width: 790px) {
    .blog-head h2 { 
      font-size: 24px;
      font-weight: 600;  
    }
 
  }